import {
  create,
  NConfigProvider,
  NMessageProvider,
  NDialogProvider,
  NAutoComplete,
  NInput,
  NButton,
  NForm,
  NFormItem,
  NFormItemGi,
  NCheckboxGroup,
  NCheckbox,
  NIcon,
  NLayout,
  NLayoutHeader,
  NLayoutContent,
  NLayoutFooter,
  NLayoutSider,
  NMenu,
  NSkeleton,
  NBreadcrumb,
  NBreadcrumbItem,
  NDropdown,
  NSpace,
  NTooltip,
  NAvatar,
  NTabs,
  NTabPane,
  NImage,
  NCard,
  NRow,
  NCol,
  NCollapse,
  NCollapseItem,
  NCollapseTransition,
  NDrawer,
  NDrawerContent,
  NDivider,
  NSwitch,
  NBadge,
  NAlert,
  NElement,
  NTag,
  NNotificationProvider,
  NProgress,
  NDatePicker,
  NGrid,
  NGridItem,
  NList,
  NListItem,
  NThing,
  NDataTable,
  NPopover,
  NPopconfirm,
  NPagination,
  NSelect,
  NRadioGroup,
  NRadio,
  NRadioButton,
  NSteps,
  NStep,
  NInputGroup,
  NInputGroupLabel,
  NButtonGroup,
  NResult,
  NDescriptions,
  NDescriptionsItem,
  NTable,
  NInputNumber,
  NLoadingBarProvider,
  NModal,
  NUpload,
  NTree,
  NSpin,
  NDynamicInput,
  NCascader,
  NPageHeader,
  NText,
  NH1,
  NH2,
  NH3,
  NH4,
  NH5,
  NH6,
  NScrollbar,
  NEllipsis,
  NPopselect,
} from "naive-ui";

export const naive = create({
  components: [
    NMessageProvider,
    NDialogProvider,
    NConfigProvider,
    NAutoComplete,
    NInput,
    NButton,
    NForm,
    NFormItem,
    NFormItemGi,
    NCheckboxGroup,
    NCheckbox,
    NIcon,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
    NLayoutFooter,
    NLayoutSider,
    NMenu,
    NSkeleton,
    NBreadcrumb,
    NBreadcrumbItem,
    NDropdown,
    NSpace,
    NTooltip,
    NAvatar,
    NTabs,
    NTabPane,
    NImage,
    NCard,
    NRow,
    NCol,
    NCollapse,
    NCollapseItem,
    NCollapseTransition,
    NDrawer,
    NDrawerContent,
    NDivider,
    NSwitch,
    NBadge,
    NAlert,
    NElement,
    NTag,
    NNotificationProvider,
    NProgress,
    NDatePicker,
    NGrid,
    NGridItem,
    NList,
    NListItem,
    NThing,
    NDataTable,
    NPopover,
    NPopconfirm,
    NPagination,
    NSelect,
    NRadioGroup,
    NRadioButton,
    NRadio,
    NSteps,
    NStep,
    NInputGroup,
    NInputGroupLabel,
    NButtonGroup,
    NResult,
    NDescriptions,
    NDescriptionsItem,
    NTable,
    NInputNumber,
    NLoadingBarProvider,
    NModal,
    NUpload,
    NTree,
    NSpin,
    NDynamicInput,
    NCascader,
    NPageHeader,
    NText,
    NH1,
    NH2,
    NH3,
    NH4,
    NH5,
    NH6,
    NScrollbar,
    NEllipsis,
    NPopselect,
  ],
});
